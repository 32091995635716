@font-face {
  font-family: "PF Din Text Universal";
  src: url("./fonts/PFDinTextUniversal-Bold.woff2") format("woff2"),
    url("./fonts/PFDinTextUniversal-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("./fonts/PFDinTextUniversal-Medium.woff2") format("woff2"),
    url("./fonts/PFDinTextUniversal-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("./fonts/PFDinTextUniversal.woff2") format("woff2"),
    url("./fonts/PFDinTextUniversal.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: #f6f6f6;
  font-family: "Roboto", sans-serif;
}

///// colors /////
$color-body: #f6f6f6;
$color-primary: #de4156;
$color-border: #bcbcbc;
$color-secondary: #20394f;
$color-white: #ffffff;
$color-white-80: #ffffffcc;
$color-white-60: #ffffff99;
$color-white-40: #ffffff66;
$color-white-20: #ffffff33;
$color-light: #989898;
$color-btngray: #464646;
$color-green: #32a12e;
$color-textMenu: #2e2e2e;
$color-textTitle: #37474f;
$color-black: #000000;
$color-selectBacground: #efefef;
$color-tableTitle: #f9f9f9;

///// theme /////
$border-radius: 4px;
$border-light: 1px solid #cfcfcf;
$border-focus: 1px solid $color-primary;

///// typography /////
$font-medium: 500;
$font-xsmall: 0.75rem; //12px
$font-small: 0.875rem; //14px
$font-normal: 1rem; //16px
$font-large: 1.25rem; //20px
$font-xlarge: 1.37rem; //24px
$font-2xlarge: 1.75rem; //28px

///// fontFamily /////
$font-main: "Roboto", sans-serif;
